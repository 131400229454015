<!--
 * @Author: Dyf
 * @Date: 2023-11-07 14:34:26
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-11-20 14:54:50
 * @Description: 校园动态详情
-->
<style lang="scss" scoped>
.news-detail {
  @include innerPage($p: 24px 0 0);
  @include pageTitle(0);

  .detail {
    &-inner {
      width: 100%;
      height: calc(100% - 96px);
      box-sizing: border-box;
      padding-left: 12px;
      padding-right: 34px;
      margin-top: 34px;
      overflow: hidden;
      overflow-y: auto;
      position: relative;

      h3 {
        line-height: 38px;
        color: #232323;
        text-align: center;
      }

      .other-info {
        height: 30px;
        font-size: 16px;
        color: #696969;
        margin-top: 8px;
        @include flexBox(center);

        span {
          margin: 0 20px;
        }
      }
    }

    &--abstract {
      width: 1046px;
      margin: 20px auto 0;
      font-size: 16px;
      line-height: 30px;
      text-align: justify;
      color: #434444;
    }

    &--rich {
      width: 100%;
      margin: 20px auto 0;

      ::v-deep {
        p,span {
          font-size: 16px;
          line-height: 30px;
          text-align: justify;
          color: #434444;
          margin-top: 10px;
        }

        img,
        video {
          display: block;
          max-width: 100%;
          border-radius: 10px;
          margin: 36px auto 0;
        }
        video {
          width: 100%;
          height: auto;
          object-fit: fill;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .news-detail {
    @include innerPage($p: 18px 0 0);
    .detail-inner .other-info {
      font-size: 14px;
    }
    .news-detail--rich {
      ::v-deep {
        p,span {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
}
</style>
<template>
  <section class="news-detail">
    <div class="page-title">
      <h3>详情</h3>
      <el-button type="custom_primary" @click="$router.go(-1)" size="medium"
        >返回</el-button
      >
    </div>
    <div class="detail-inner" v-loading="dataload">
      <h3 class="bold">{{ detailData.plinf_title }}</h3>
      <div class="other-info pf_medium">
        <span>日期：{{ detailData.create_time }}</span>
        <span>来源：{{ detailData.plinf_type_name }}</span>
        <span>阅读量：{{ detailData.plinf_read_num || 0 }}次</span>
        <span>点赞量：{{ detailData.plinf_praise_num || 0 }}次</span>
      </div>
      <p class="detail--abstract" v-if="detailData.plinf_precis">
        摘要：{{ detailData.plinf_precis }}
      </p>
      <div class="detail--rich" v-html="detailData.plinf_content"></div>
    </div>
  </section>
</template>

<script>
import { stuinformationInfo } from "@api/dynamic";
import { formatFile } from "@utils";
export default {
  computed: {
    formatfile() {
      return function (url) {
        return formatFile(url);
      };
    },
  },
  data() {
    return {
      dataload: false,
      detailData: {},
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    /** 获取资讯详情 */
    async getDetail() {
      this.dataload = true;
      let { data } = await stuinformationInfo({
        plinf_id: this.$route.params.id,
      });
      this.dataload = false;
      this.detailData = data;
    },
  },
};
</script>